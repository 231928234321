import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular/standalone';

export enum PlatformType {
  IOS = 'Ios',
  Android = 'android',
  WebDesktop = 'webDesktop',
  WebMobile = 'webMobile',
}

@Injectable({ providedIn: 'root' })
export class AppService {
  isMobile$!: BehaviorSubject<boolean>;
  isTablet$!: BehaviorSubject<boolean>;

  readonly platformType: PlatformType = this.getPlatform();

  private readonly mobileResolution = 768;
  private readonly desktopResolution = 1280;

  constructor(private platform: Platform) {}

  initialize(): void {
    this.updateIsMobileView(window.innerWidth);
  }

  updateIsMobileView(innerWidth: number): void {
    const isMobile = innerWidth < this.mobileResolution;
    const isTablet = innerWidth <= this.desktopResolution && innerWidth >= this.mobileResolution;

    if (this.isMobile$) {
      this.isMobile$.next(isMobile);
      this.isTablet$.next(isTablet);
    } else {
      this.isMobile$ = new BehaviorSubject<boolean>(isMobile);
      this.isTablet$ = new BehaviorSubject<boolean>(isTablet);
    }
  }

  private getPlatform(): PlatformType {
    if (this.platform.is('desktop')) {
      return PlatformType.WebDesktop;
    } else if (this.platform.is('mobileweb')) {
      return PlatformType.WebMobile;
    } else if (this.platform.is('ios')) {
      return PlatformType.IOS;
    } else {
      return PlatformType.Android;
    }
  }
}
